.App {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: space-evenly; */
  font-size: calc(10px + 2vmin);
  color: white;
}

.PasswordСontainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}

.PasswordСontainer > input {
  width: 50%;
  height: 30px;
  font-size: large;
}

.Links {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  /* min-height: 100vh; */

  /* width: 100px; */
  /* height: 100px; */
}

.Links > a {
  text-decoration: none;
  color: #3e64ff;
  /* background-color: #fff; */
  background-color: transparent;
  border: 1px solid transparent;
  border-color: #3e64ff;
  padding: 1ch;
}

.Links > a:hover {
  text-decoration: none;
  color: #fff;
  /* background-color: #fff; */
  background-color: #3e64ff;
  border: 1px solid transparent;
  border-color: #3e64ff;
  padding: 1ch;
}

.Links > a:active {
  text-decoration: none;
  color: rgb(212, 202, 202);
  /* background-color: #fff; */
  background-color: #15266d;
  border: 1px solid transparent;
  border-color: #3e64ff;
  padding: 1ch;
}

.Users {
  /* border: 10px solid; */
  display: flex;
  flex-direction: column;
}
.User {
  color: #61dafb;
  /* display: flex; */

  /* justify-content: space-evenly; */

}

.Errors{
  display: flex;
  flex-direction: column;
}

.id::before{
  content: "#";
}

.id {
  color: white;
  background-color: #17191b;
  /* padding: 5px; */
  margin: 5px;
  border-radius: 5px;
}

.username{
  margin: 5px;
}

.base64{
  display: block;
  background-color: #282c34;
  width: 600px;
  color: #eee9e9;
}

.errormsg{
  display: block;
  background-color: #282c34;
  color: #e94747;
}

